import React from 'react'
import { Recepie } from "../assets/Recepie"

function DisplayRecepie() {

    return (
        <div style={{ paddingTop: "72px" }}>
            {
                Recepie.map(post => {
                    return (
                        <div key={post.id} style={{ maxWidth: "860px", margin: "20px", paddingBottom: "20px", backgroundColor: "#555", borderRadius: "15px", borderWidth: "5px", borderColor: "#777", borderStyle: "solid" }}>
                            <h2>{post.header1}</h2>
                            <img src={post.img} alt="" className={post.imgclass} /><br />
                            <h2>{post.header2}</h2>
                            {post.list === "" ? "" : List(post.list)}
                            <div style={{padding: 15}}>{post.text}</div>
                        </div>
                    )
                })
            }
        </div>
    )
}


function List(item) {
    return (
        <>
            <ul>
                {item.map(getitem => (
                    <li >{getitem}</li>
                ))
                }
            </ul>
        </>
    )
}

export default DisplayRecepie
