import React from 'react'

function Track05() {
    return (
        <div style={{
            maxWidth: "860px",
            margin: "20px",
            paddingLeft: "20px",
            paddingRight: "20px",
            backgroundColor: "#555",
            borderRadius: "15px",
            borderWidth: "5px",
            borderColor: "#777",
            borderStyle: "solid"
        }}>
            <h1>Goliath</h1>
            <iframe 
                // width="560"
                // height="315"
                className= "video"
                style={{ borderRadius: "15px" }}
                src="https://www.youtube.com/embed/tVRbWzQ9kaE?si=wTHZD9iNK3Bg6Sje&amp;start=1"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; 
                    autoplay;
                    clipboard-write;
                    encrypted-media;
                    gyroscope;
                    picture-in-picture;
                    web-share"
                allowfullscreen>
                </iframe>
            <h1>Smith & Tell</h1>
        </div>
    )
}

export default Track05
