import React from 'react'
import foto_gustafs_lasagne from "../assets/pics/gustafs_lasagne.jpg"

function Home() {
    const gustaf_lagar_lasagne = "https://www.youtube.com/watch?v=Rinf3VRydUI";
    
    return (
        <>
            <div className="container" style={{paddingTop: "70px"}}>
                <h2 style={{color: "azure"}}>Hej, detta är ett<br />recept på lasagne</h2>
                <a href={gustaf_lagar_lasagne} alt="Gustaf lagar lasagne" target="_blank" rel="noreferrer">
                    <img src={foto_gustafs_lasagne} alt="Katten Gustaf" style={{ width: "300px", borderRadius: "35px" }} />
                </a>
                <p>Efter önskemål från min son Joel, att jag skulle dela med mig av<br />mitt recept på lasagne till hans
                    födelsedag. Så kommer det här.
                    <br />
                    <h4> Klicka på bilden.</h4>
                </p>
            </div>
        </>
    )
}

export default Home
