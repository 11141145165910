import React from 'react'

function About() {
    return (
        <>
            <div className="">
                    <h2 className="josefin-sans-lk" style={{ position: "relative", top: "-30vh" }}>
                        Musik kan inspirera! Hoppas denna lista på låtar kan inspirera...</h2>
                    <p className="josefin-sans-lk" style={{ position: "relative", top: "-30vh" }}>
                        ...alla till nya krafter. I hemmet, bakom datorn, i skolbänken, på jobbet, i skogen, på stan, i livet,<br/>
                        eller vart ni än må vara.
                    </p>

            </div>
        </>
    )
}

export default About
