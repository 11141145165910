import React from 'react'
import foto_gustafs_lasagne from "../assets/pics/FotoFromExamYA.jpg"

function Home() {
    const gustaf_lagar_lasagne = "https://www.linkedin.com/in/lars-karlqvist-582b7a28/";

    return (
        <>
            <div className="">
                <h2 className="josefin-sans-lk" style={{ color: "#1491f0" }}>Hej, är detta ett<br />recept på bra musik?</h2>
                <a href={gustaf_lagar_lasagne} alt="Gustaf lagar lasagne" target="_blank" rel="noreferrer">
                    <img src={foto_gustafs_lasagne} alt="Katten Gustaf" style={{ width: "200px", height: "200px", borderRadius: "100px", border: "7px solid #1491fb", boxShadow: "0em 0em 1em 0.3em #777" }} />
                </a>
                <p className="josefin-sans-lk" style={{ color: "#111" }}>Eller bara ytterligare ett exempel på hur man kan bygga en scrollande meny.<br />
                    Ja kanske det? <br/>
                    Det är ett exempel på en design man kan ge sin hemsida och det<br/> 
                    mesta är möjligt att skapa i form av utseende och layout. <br/>
                    För att fylla denna layouten så har jag lagt in några <br/>
                    av mina personliga favorit låtar på Youtube.<br/>
                    Det finns flera men som är bra,<br/> 
                    men detta är mest för att visa ett koncept.<br/>
                    Ett exempel på vad man kan göra.
                    <br />
                </p>
            </div>
        </>
    )
}

export default Home
