import React from 'react'
import { NavHashLink } from 'react-router-hash-link';
import { Link, useMatch, useResolvedPath } from 'react-router-dom'


function Navbar({ activHome, activAbout, activTracks, activT1, activT2, activT3 }) {

    return (
        <>
            <header-l>
                <nav-l id="navbar" className="navbar">
                    <NavHashLink
                        onClick={() => { closeTrackList(this) }}
                        to="/music/#home"><a
                            data-scroll="home"
                            href="#home"
                            className={test(activHome)}
                            id="menu-home"> Hem </a></NavHashLink>
                    <NavHashLink
                        onClick={() => { closeTrackList(this) }}
                        to="/music/#about"><a
                            data-scroll="about"
                            href="#about"
                            className={test(activAbout)}
                            id="menu-about"> Om </a></NavHashLink>
                    <NavHashLink
                        onClick={() => { openTrackList(this) }}
                        to="/music/#tracks"><a
                            data-scroll="tracks"
                            href="#tracks"
                            className={test(activTracks)}
                            id="menu-track"
                        > Låtlista </a></NavHashLink>
                    <div >
                        <SubMenuTrack />
                    </div>
                    {/* <NavHashLink to="/music/#info"> Info </NavHashLink> */}
                </nav-l>
                <span id="menu-icon">
                    <span id="closedmenu" onClick={() => { openMobileMenu(this) }} className="material-symbols-outlined" > menu </span>
                    <span id="openmenu" onClick={() => { closeMobileMenu(this) }} className="material-symbols-outlined"> close </span>
                    <menu id="mobilemenu" className="mobile-menu" style={{ display: 'none' }}>
                        <NavHashLink
                            onClick={() => { closeMobileMenu(this) }}
                            to="/music/#home"><a
                                data-scroll="home"
                                href="#home"
                                className={test(activHome)} > Hem </a></NavHashLink>
                        <NavHashLink
                            onClick={() => { closeMobileMenu(this) }}
                            to="/music/#about"><a
                                data-scroll="about"
                                href="#about"
                                className={test(activAbout)}> Om </a></NavHashLink>
                        <NavHashLink
                            onClick={() => { toggleMobTrackList(this) }}
                            to="/music/#tracks"><a
                                data-scroll="tracks"
                                href="#tracks"
                                id="track-list"
                                className={test(activTracks)}> Låtlista </a></NavHashLink>
                        <div >
                            <SubMobilTrack />
                        </div>
                        {/* <NavHashLink to="/music/#info"><a href='#info'> Info </a></NavHashLink> */}
                    </menu>
                </span>
            </header-l>
        </>
    )
}

function SubMenuTrack(atT1, atT2, atT3) {
    return (
        <div id="sub-menu" className='sub-menu'>
            <NavHashLink
                onClick={() => { closeTrackList(this) }}
                to="/music/#t1"><a data-scroll="t1" href="#t1" className={test(atT1)} > Umbrella </a></NavHashLink>
            <NavHashLink
                onClick={() => { closeTrackList(this) }}
                to="/music/#t2"><a data-scroll="t2" href="#t2" className={test(atT2)}> Walking Groove </a></NavHashLink>
            <NavHashLink
                onClick={() => { closeTrackList(this) }}
                to="/music/#t3"><a data-scroll="t3" href="#t3" className={test(atT3)}> Too Hot </a></NavHashLink>
            <NavHashLink
                onClick={() => { closeTrackList(this) }}
                to="/music/#t4"><a data-scroll="t4" href="#t4" > Brothers In Arms </a></NavHashLink>
            <NavHashLink
                onClick={() => { closeTrackList(this) }}
                to="/music/#t5"><a data-scroll="t5" href="#t5"> Goliath </a></NavHashLink>
            <NavHashLink
                onClick={() => { closeTrackList(this) }}
                to="/music/#t6"><a data-scroll="t6" href="#t6"> Bakerman </a></NavHashLink>
            <NavHashLink
                onClick={() => { closeTrackList(this) }}
                to="/music/#t7"><a data-scroll="t7" href="#t7" > Boogie </a></NavHashLink>
            <NavHashLink
                onClick={() => { closeTrackList(this) }}
                to="/music/#t8"><a data-scroll="t8" href="#t8" > Ave Maria </a></NavHashLink>
            <NavHashLink
                onClick={() => { closeTrackList(this) }}
                to="/music/#t9"><a data-scroll="t9" href="#t9" > Run through the jungle </a></NavHashLink>
            <NavHashLink
                onClick={() => { closeTrackList(this) }}
                to="/music/#t10"><a data-scroll="t10" href="#t10" > You Learn </a></NavHashLink>
            <NavHashLink
                onClick={() => { closeTrackList(this) }}
                to="/music/#t11"><a data-scroll="t11" href="#t11"> Adagio </a></NavHashLink>
            <NavHashLink
                onClick={() => { closeTrackList(this) }}
                to="/music/#t12"><a data-scroll="t12" href="#t12"> Cavatina </a></NavHashLink>
        </div>
    )
}

function SubMobilTrack() {

    return (
        <div id="sub-mobmenu" className='sub-mobmenu'>
            <NavHashLink
                onClick={() => { closeMobileMenu(this) }}
                to="/music/#t1"><a data-scroll="t1" href="#t1" > Umbrella </a></NavHashLink>
            <NavHashLink
                onClick={() => { closeMobileMenu(this) }}
                to="/music/#t2"><a data-scroll="t2" href="#t2" > Walking Groove </a></NavHashLink>
            <NavHashLink
                onClick={() => { closeMobileMenu(this) }}
                to="/music/#t3"><a data-scroll="t3" href="#t3" > Too Hot </a></NavHashLink>
            <NavHashLink
                onClick={() => { closeMobileMenu(this) }}
                to="/music/#t4"><a data-scroll="t4" href="#t4" > Brothers In Arms </a></NavHashLink>
            <NavHashLink
                onClick={() => { closeMobileMenu(this) }}
                to="/music/#t5"><a data-scroll="t5" href="#t5"> Goliath </a></NavHashLink>
            <NavHashLink
                onClick={() => { closeMobileMenu(this) }}
                to="/music/#t6"><a data-scroll="t6" href="#t6"> Bakerman </a></NavHashLink>
            <NavHashLink
                onClick={() => { closeMobileMenu(this) }}
                to="/music/#t7"><a data-scroll="t7" href="#t7" > Boogie </a></NavHashLink>
            <NavHashLink
                onClick={() => { closeMobileMenu(this) }}
                to="/music/#t8"><a data-scroll="t8" href="#t8" > Ave Maria </a></NavHashLink>
            <NavHashLink
                onClick={() => { closeMobileMenu(this) }}
                to="/music/#t9"><a data-scroll="t9" href="#t9" > Run through the jungle </a></NavHashLink>
            <NavHashLink
                onClick={() => { closeMobileMenu(this) }}
                to="/music/#t10"><a data-scroll="t10" href="#t10" > You Learn </a></NavHashLink>
            <NavHashLink
                onClick={() => { closeMobileMenu(this) }}
                to="/music/#t11"><a data-scroll="t11" href="#t11"> Adagio </a></NavHashLink>
            <NavHashLink
                onClick={() => { closeMobileMenu(this) }}
                to="/music/#t12"><a data-scroll="t12" href="#t12"> Cavatina </a></NavHashLink>
        </div>
    )
}

function closeTrackList(menu) {
    document.getElementById("sub-menu").style.display = "none";
}

function openTrackList(menu) {
    document.getElementById("sub-menu").style.display = "flex";
}

function closeMobTrackList(menu) {
    document.getElementById("sub-mobmenu").style.display = "none";
}

function openMobTrackList(menu) {
    document.getElementById("sub-mobmenu").style.display = "flex";
}

function toggleMobTrackList(menu) {
    if (document.getElementById("sub-mobmenu").checkVisibility()) {
        document.getElementById("sub-mobmenu").style.display = "none";
    } else {
        document.getElementById("sub-mobmenu").style.display = "flex";
    }
    console.log("toggleMobTrackList()");
}

var test = (active) => {
    return (active === true ? "active" : "")
}

function openMobileMenu(menu) {
    document.getElementById("closedmenu").style.display = "none";
    document.getElementById("openmenu").style.display = "inline";
    document.getElementById("mobilemenu").style.display = "flex";

    document.getElementById("sub-mobmenu").style.display = "none";

    console.log("openMobileMenu()");
}

function closeMobileMenu(menu) {
    document.getElementById("closedmenu").style.display = "inline";
    document.getElementById("openmenu").style.display = "none";
    document.getElementById("mobilemenu").style.display = "none";

    console.log("closeMobileMenu()");
}

export default Navbar
