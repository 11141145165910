import React from 'react'

function Track08() {
    return (
        <div style={{
            maxWidth: "860px",
            margin: "20px",
            paddingLeft: "20px",
            paddingRight: "20px",
            backgroundColor: "#555",
            borderRadius: "15px",
            borderWidth: "5px",
            borderColor: "#777",
            borderStyle: "solid"
        }}>
            <h1>Ave Maria, F. Schubert</h1>
            <iframe 
                // width="560"
                // height="315"
                className= "video"
                style={{ borderRadius: "15px" }}
                src="https://www.youtube.com/embed/Jdy-tiwPD5o?si=JhkbS0RkYmM-znEo&amp;start=1"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; 
                    autoplay; 
                    clipboard-write; 
                    encrypted-media; 
                    gyroscope; 
                    picture-in-picture; 
                    web-share"
                allowfullscreen>
            </iframe>
            <h1>Anastasiya Petryshak</h1>
        </div>
    )
}

export default Track08
