import foto_20240114_163337 from "../assets/pics/20240114_163337.jpg"
import foto_20240114_163510 from "../assets/pics/20240114_163510.jpg"
import foto_20240114_164039 from "../assets/pics/20240114_164039.jpg"
import foto_20240114_170317 from "../assets/pics/20240114_170317.jpg"
import foto_20240114_170506 from "../assets/pics/20240114_170506.jpg"
import foto_20240114_170725 from "../assets/pics/20240114_170725.jpg"
import foto_20240114_170826 from "../assets/pics/20240114_170826.jpg"
import foto_20240114_170909 from "../assets/pics/20240114_170909.jpg"
import foto_20240114_170942 from "../assets/pics/20240114_170942.jpg"
import foto_20240114_171002 from "../assets/pics/20240114_171002.jpg"
import foto_20240114_171550 from "../assets/pics/20240114_171550.jpg"
import foto_20240114_172202 from "../assets/pics/20240114_172202.jpg"
import foto_20240114_172408 from "../assets/pics/20240114_172408.jpg"
import foto_20240114_173126 from "../assets/pics/20240114_173126.jpg"
import foto_20240114_174738 from "../assets/pics/20240114_174738.jpg"
import foto_20240114_174820 from "../assets/pics/20240114_174820.jpg"
import foto_20240114_175116 from "../assets/pics/20240114_175116.jpg"


export const Recepie =[
    {
        "id": 1,
        "img": foto_20240114_163337,
        "imgclass": "image",
        "header1": "Recept på lasagne",
        "header2": "Ingredienser:",
        "list": [
            "Pastaplattor",
            "Gul lök (1 st)",
            "Ost",
            "Köttfärs (blandfärs 500g)",
            "Krossade tomater (400g helst med krydda tex. basilika)",
            "Tacosås",
            "Olja",
            "Vetemjöl",
            "Kryddor"
        ],
        "text": ""
    },
    {
        "id": 2,
        "img": foto_20240114_163510,
        "imgclass": "image",
        "header1": "Tidsåtgång ca. 40-45min.",
        "header2": "Redskap:",
        "list": [
            "Stekpanna",
            "Ungsfast form",
            "Kastrull/Gryta (en stor och en liten 1.5l)",
            "Visp till att reda ostsåsen",
            "Stekspade samt slev att röra med och kniv till att hacka löken"
        ],
        "text": ""
    },
    {
        "id": 3,
        "img": foto_20240114_164039,
        "imgclass": "image",
        "header1": "",
        "header2": "",
        "list": "",
        "text": "Stek löken i stekpannan, när den är lätt gyllenbrun lägg undan i en lite gryta. Fräs köttfärsen i samma panna, se till att den blir välstekt men ej bränd."
    },
    {
        "id": 4,
        "img": foto_20240114_170317,
        "imgclass": "image",
        "header1": "",
        "header2": "",
        "list": "",
        "text": "Blanda krossade tomater, den stekta löken och köttfärsen i en större gryta."
    },
    {
        "id": 5,
        "img": foto_20240114_170506,
        "imgclass": "image-potrait",
        "header1": "",
        "header2": "",

        "list": "",
        "text": "Rör om, krydda med salt och peppar lite sambal olek och chilli flingor."
    },
    {
        "id": 6,
        "img": foto_20240114_170725,
        "imgclass": "image",
        "header1": "",
        "header2": "",
        "list": "",
        "text": "Fortsätt röra, smaka av med salt och kryddor. Prova försiktigt och salta inte för mycket. Låt stå på låg värme och puttra."
    },
    {
        "id": 7,
        "img": foto_20240114_170826,
        "imgclass": "image",
        "header1": "",
        "header2": "",
        "list": "",
        "text": "Skölj nu den lilla kastrullen som löken förvarades i med vatten. Tillsätt olja ca 2st matskedar."
    },
    {
        "id": 8,
        "img": foto_20240114_170909,
        "imgclass": "image",
        "header1": "",
        "header2": "",
        "list": "",
        "text": "Tillsätt en rågad matsked vetemjöl."
    },
    {
        "id": 9,
        "img": foto_20240114_170942,
        "imgclass": "image",
        "header1": "",
        "header2": "",
        "list": "",
        "text": "Rör ner mjölet i oljan med visp."
    },
    {
        "id": 10,
        "img": foto_20240114_171002,
        "imgclass": "image",
        "header1": "",
        "header2": "",
        "list": "",
        "text": "Rör till en jämn smet. Börja försiktig med att fylla på med mjölk (helst helmjölk 3% fett) rör om. Redningen kommer att tjockna när den puttrar på låg värme. Då fyller man på med mer mjölk under omrörning för att få en lagom tjock sås. Fortsätt röra och fylla på med mjölk tills redningen känns lagom."
    },
    {
        "id": 11,
        "img": foto_20240114_171550,
        "imgclass": "image",
        "header1": "",
        "header2": "",
        "list": "",
        "text": "Krydda redningen med vitpeppar och tillsätt en del av osten för smakens skull."
    },
    {
        "id": 12,
        "img": foto_20240114_172202,
        "imgclass": "image",
        "header1": "Nu är allt klart för att fylla på den/de ungsfasta formen/formarna.",
        "header2": "",
        "list": "",
        "text": "Börja men att fylla lite av ostsåsen i botten på formen. Lägg därefter första lagret men lasagneplattor. Fortsätt därefter med köttfärssåsen. Toppa med ytterligare att lager lasagneplattor."
    },
    {
        "id": 13,
        "img": foto_20240114_172408,
        "imgclass": "image",
        "header1": "",
        "header2": "",
        "list": "",
        "text": "Varva med köttfärssås och ostsås och avsluta med ett lager lasagneplattor. Toppa med ostskivor som täcker lasagnen helt."
    },
    {
        "id": 14,
        "img": foto_20240114_173126,
        "imgclass": "image",
        "header1": "",
        "header2": "",
        "list": "",
        "text": "Sätt in i ugnen som är förvärmd till 225°C."
    },
    {
        "id": 15,
        "img": foto_20240114_174738,
        "imgclass": "image",
        "header1": "",
        "header2": "",
        "list": "",
        "text": "Grädda i ungen i ca 15 min."
    },
    {
        "id": 16,
        "img": foto_20240114_174820,
        "imgclass": "image",
        "header1": "",
        "header2": "",
        "list": "",
        "text": "Tag ut ur ungnen när lasagnen fått lätt brynt färg på osten i kanterna."
    },
    {
        "id": 17,
        "img": foto_20240114_175116,
        "imgclass": "image",
        "header1": "",
        "header2": "",
        "list": "",
        "text": "Klart att servera!"

    }
]