import React from 'react'

function Track10() {
    return (
        <div style={{
            maxWidth: "860px",
            margin: "20px",
            paddingLeft: "20px",
            paddingRight: "20px",
            backgroundColor: "#555",
            borderRadius: "15px",
            borderWidth: "5px",
            borderColor: "#777",
            borderStyle: "solid"
        }}>
            <h1>You Learn</h1>
            <iframe 
                // width="560"
                // height="315"
                className= "video"
                style={{ borderRadius: "15px" }}
                src="https://www.youtube-nocookie.com/embed/E5erP0eJ0AM?si=MgQ6Skanj0jxDnb-&amp;start=11"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; 
                    autoplay; 
                    clipboard-write; 
                    encrypted-media; 
                    gyroscope; 
                    picture-in-picture; 
                    web-share"
                allowfullscreen>
            </iframe>
            <h1>Takida</h1>
        </div>
    )
}

export default Track10
