import React from 'react'

function Track06() {
    return (
        <div style={{ maxWidth: "860px", margin: "20px", paddingLeft: "20px", paddingRight: "20px", backgroundColor: "#555", borderRadius: "15px", borderWidth: "5px", borderColor: "#777", borderStyle: "solid" }}>
            <h1>Bakerman</h1>
            <iframe 
            // width="560" 
            // height="315" 
            className= "video"
            style={{borderRadius:"15px"}} 
            src="https://www.youtube.com/embed/1VQ2N-iKe1s?si=nPARocS_xNBDf7SG&amp;start=10" 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; 
                autoplay;
                clipboard-write;
                encrypted-media;
                gyroscope;
                picture-in-picture;
                web-share"
                allowfullscreen>
            </iframe>
            <h1>Laidback</h1>
        </div>
    )
}

export default Track06
