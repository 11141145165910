import React from 'react'

function About() {
    return (
        <>
            <div className="container">
                <div style={{ top: "100px" }}>
                    <h2 style={{ position: "relative", top: "-2vh" }}>
                        Grattis på födelsedagen Joel! Hoppas detta kan inspirera...</h2>
                    <p style={{ position: "relative", top: "-2vh", width: "60vw" }}>
                        ...till nya krafter. I köket, bakom datorn, i skolbänken, på jobbet, i skogen, på stan, i livet.
                    </p>
                </div>
            </div>
        </>
    )
}

export default About
