import Navbar from './components/Navbar';
import Home from './pages/Home';
import History from './pages/History';
import HistoryEng from './pages/HistoryEng';
import Mystory from './pages/Mystory';
import MystoryEng from './pages/MystoryEng';
import About from './pages/About';
import AboutEng from './pages/AboutEng';
import Services from './pages/Services'
import ServicesEng from './pages/ServicesEng'
import Projects from './pages/Projects';
import ProjectsEng from './pages/ProjectsEng';
import Contact from './pages/Contact';
import Record from './pages/Record';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import SiteFooter from './components/SiteFooter'
import Lasagne from './pages/lasagne/Lasagne';
import HomeLasange from './pages/lasagne/pages/Home';
import HomeAbout from './pages/lasagne/pages/About';
import HomeWork from './pages/lasagne/pages/Work';
import Music from './pages/music/Music';
import MusicHome from './pages/music/pages/Home';
import MusicAbout from './pages/music/pages/About';
import MusicTracks from './pages/music/assets/tracks/Tracks';
import MusicInfo from './pages/music/pages/Info';

function App() {

  return (
    <BrowserRouter basename="/">
      {/* <Navbar /> */}
      <Routes>
        <Route exact path='/' element={<Home />} />
        {/* <Route path='/history' element={<History />} />
        <Route path='/history-eng' element={<HistoryEng />} />
        <Route path='/mystory' element={<Mystory />} />
        <Route path='/mystory-eng' element={<MystoryEng />} />
        <Route path='/about' element={<About />} />
        <Route path='/about-eng' element={<AboutEng />} />
        <Route path='/services' element={<Services />} />
        <Route path='/services-eng' element={<ServicesEng />} />
        <Route path='/projects' element={<Projects />} />
        <Route path='/projects-eng' element={<ProjectsEng />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/record' element={<Record />} /> */}
        <Route path='/lasagne' element={<Lasagne />} />
        <Route path='/lasagne/#home' element={<HomeLasange />} />
        <Route path='/lasagne/#about' element={<HomeAbout />} />
        <Route path='/lasagne/#work' element={<HomeWork />} />
        <Route path='/music' element={<Music />} />
        <Route path='/mucic/#home' element={<MusicHome />} />
        <Route path='/music/#about' element={<MusicAbout />} />
        <Route path='/music/#tracks' element={<MusicTracks />} />
        <Route path='/music/#info' element={<MusicInfo />} />
        <Route path='*' element={<Home />} />
      </Routes>
      <div style={{ display: "none" }}>
        <SiteFooter />
      </div>
    </BrowserRouter>
  )

}

export default App;
