import React from 'react'
import { useInView } from 'react-intersection-observer';
import DisplayRecepie from '../components/DisplayRecepie'

function Work() {
  const { ref: refHart, inView: atHart } = useInView({threshold: 0.5});
  console.log("Vid bonne apetit", atHart);
  return (
    <>
        <div className="container">
          <DisplayRecepie/>
            <br/>
            <h1 ref={refHart}> <span className={`${"hart"} ${ atHart ? "animateHart":""}`}>
              💝
            </span>
            <br />Bonne Apetité</h1>
            <br /><br />
        </div>
    </>
  )
}

export default Work
