export default function Home() {
  window.scrollTo(0, 0);
  return (
    <div className='container'>
      <div className="sloganFixed"><p>Work for a better tomorrow</p><br /><br />
        <p style={{ color: "#09c372" }}>Min nya sida hittar ni på <br />
          <a style={{ color: "#09c372", fontSize: "30px" }} href="https://www.larskarlqvist.se">www.larskarlqvist.se</a></p>
      </div>
    </div>
  )
}
