import React from 'react'

function Track04() {
    return (
        <div style={{
            maxWidth: "860px",
            margin: "20px",
            paddingLeft: "20px",
            paddingRight: "20px",
            backgroundColor: "#555",
            borderRadius: "15px",
            borderWidth: "5px",
            borderColor: "#777",
            borderStyle: "solid"
        }}>
            <h1>Brothers In Arms (Berlin 2007 | Official Live Video)</h1>
            <iframe
                // width="560"
                // height="315"
                className= "video"
                style={{ borderRadius: "15px" }}
                src="https://www.youtube.com/embed/EMRJT2ebvAk?si=ryGoG85iOOzadBbR&amp;start=1"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; 
                    autoplay; 
                    clipboard-write; 
                    encrypted-media; 
                    gyroscope; 
                    picture-in-picture; 
                    web-share"
                allowfullscreen>
            </iframe>
            <h1>Mark Knopfler</h1>
        </div>
    )
}

export default Track04
