import React from 'react'
import background from "../pics/screen-scanda-content-tulips-care-7-20200.jpg"

function Track03() {
    return (
        <div style={{
            backgroundImage: `url(${background})`,
            maxWidth: "860px",
            margin: "20px",
            paddingLeft: "20px",
            paddingRight: "20px",
            backgroundColor: "#555",
            borderRadius: "15px",
            borderWidth: "5px",
            borderColor: "#777",
            borderStyle: "solid",
            color: "#1491f0"
        }}>
            <h1>Too Hot</h1>
            <iframe
                // width="560"
                // height="315"
                className= "video"
                style={{ borderRadius: "15px" }}
                src="https://www.youtube.com/embed/b4prV4rHbT4?si=GW_inrkHEx1nF4Fq"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; 
                    autoplay; 
                    clipboard-write; 
                    encrypted-media; 
                    gyroscope; 
                    picture-in-picture; 
                    web-share"
                allowfullscreen>
            </iframe>
            <h1>Kool & The Gang</h1>
        </div>
    )
}

export default Track03
