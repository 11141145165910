import React from 'react'
import { useInView } from 'react-intersection-observer';
import { NavHashLink } from 'react-router-hash-link';
import arrow_down from "../pics/arrow_down.jpg"

function TrackHeader() {

  return (
    <>
      <div className="container" style={{ minHeight: "18vh", height: "20vh" }}>
        <h2 className="josefin-sans-lk" style={{ fontSize: "50px", height: "10vh" }}>Låtlistan</h2>
        <NavHashLink
         onClick={() => { closeTrackList(this) }}
          to="/music/#hart"><a
            data-scroll="hart"
            href="#hart">
            <img src={arrow_down}/></a>
        </NavHashLink>
      </div>
    </>
  )
}

function closeTrackList(menu) {
  document.getElementById("sub-menu").style.display = "none";
}

export default TrackHeader