import React from 'react'
import { NavHashLink } from 'react-router-hash-link';

function Navbar({ activHome, activAbout, activWork }) {
    return (
        <>
            <header-l>
                <nav-l id="navbar" className="navbar">
                <NavHashLink to="/lasagne/#home"><a data-scroll="home" href="#home" className={test(activHome)} id="menu-home"> Hem </a></NavHashLink>
                <NavHashLink to="/lasagne/#about"><a data-scroll="about" href="#about" className={test(activAbout)} id="menu-about"> Om </a></NavHashLink>
                <NavHashLink to="/lasagne/#work"><a data-scroll="work" href="#work" className={test(activWork)} id="menu-work"> Receptet </a></NavHashLink>
                </nav-l>
                <div id="menu-icon" onClick={() => { toggleMobileMenu(this) }}>
                    <span id="closedmenu" className="material-symbols-outlined" > menu </span>
                    <span id="openmenu" className="material-symbols-outlined"> close </span>
                    <menu id="mobilemenu" className="mobile-menu" style={{ display: 'none' }}>
                    <NavHashLink to="/lasagne/#home"><a data-scroll="home" href="#home"  className={test(activHome)} > Hem </a></NavHashLink>
                    <NavHashLink to="/lasagne/#about"><a data-scroll="about" href="#about" className={test(activAbout)}> Om </a></NavHashLink>
                    <NavHashLink to="/lasagne/#work"><a data-scroll="work" href="#work" className={test(activWork)}> Receptet </a></NavHashLink>
                    </menu>
                </div>
            </header-l>
        </>
    )
}

var test = (active) => {
    return (active === true ? "active" : "")
}

var toggleMobileMenu = (menu) => {
    console.log("toggle menu")
    if (document.getElementById("closedmenu").checkVisibility()) {

        document.getElementById("closedmenu").style.display = "none";
        document.getElementById("openmenu").style.display = "inline";
        document.getElementById("mobilemenu").style.display = "flex";

        console.log("toggle 1:a villkoret");
    } else {

        document.getElementById("closedmenu").style.display = "inline";
        document.getElementById("openmenu").style.display = "none";
        document.getElementById("mobilemenu").style.display = "none";

        console.log("toggle 2:a villkoret");
    }
    console.log(document.getElementById("openmenu").checkVisibility())
    console.log(document.getElementById("closedmenu").checkVisibility())
};

export default Navbar
